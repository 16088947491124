import React, { useCallback, useState, useRef } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"
import NavBar from "../../components/NavBar/NavBar"
import Popup from "../../componentForPages/Popup/Popup"
import { isSSR } from "../../components/Script/script"

import ScrollEmojiWindow from "./components/ScrollListWindow"

import {
  Title,
  Input,
  Button,
  Dropdown,
  Field,
  Control,
  Icon,
  ArrowDown,
} from "../../StyleComponents/styles"
import { FlexDiv, Table } from "../../StyleComponents/pagesStyle"

const DropDownList = React.lazy(() => import("./components/DropDown"))
const UsageOfCodePoint = React.lazy(() =>
  import("./components/usageOfCodePoint")
)

const seeAlaoArray = [
  "/emoticons-copy-and-paste",
  "/font-generator",
  "/text-emoticons-face-generator",
  "/html-entity",
  "/text-art",
]

function EmojiNamesList(props) {
  const { singlePageInfo, everyEmoji, allPages } = props.pageContext
  let [emoji, setEmoji] = useState("")
  let [show, setShow] = useState(false)
  let smallInput = useRef("")

  const mycopy = useCallback(e => {
    let emoji = e.target.value
    e.target.select()
    let copied = document.execCommand("copy")
    if (copied) {
      setEmoji(emoji)
      setShow(true)
      setTimeout(function () {
        setShow(false)
      }, 1500)
    }
  }, [])

  const copyButton = e => {
    let button = e.target
    smallInput.current.select()
    let copied = document.execCommand("copy")
    if (copied) {
      button.textContent = "Copied 👍"
      setTimeout(function () {
        button.textContent = "Copy"
      }, 1500)
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title={`${singlePageInfo.emoji} ${singlePageInfo.name} Emoji Copy And Paste`}
        description={`${singlePageInfo.name} Emoji Copy And 📋Paste ${singlePageInfo.emoji} - Simple beautiful Copy Paste Emoji, Symbols, People and Fantasy, All flag emojis for every country, Travel & Places, Activity and Sports, Food & Drink...`}
        keywords={[
          `copy ${singlePageInfo.name} emoji,${singlePageInfo.name} Emoji,${
            singlePageInfo.name
          } emoji copy,${singlePageInfo.name} emoji Symbols,emoji ${
            singlePageInfo.name
          } copy and paste,emoji ${
            singlePageInfo.name
          }, Pale Emojis,emoji smiley, emoji callery, nice emoji,${new Date().getFullYear()} emoji.`,
        ]}
      />
      <NavBar
        listPages={React.useMemo(() => {
          return [
            { name: "Utility Tools", link: "/utility-tools/" },
            { name: "All 🤯 EMOJI", link: "/emoji-copy-and-paste/" },
            {
              name: singlePageInfo.name,
              link: `/${singlePageInfo.name
                .replace(":", "")
                .trim()
                .split(" ")
                .join("-")
                .toLowerCase()}-emoji/`,
            },
          ]
        }, [singlePageInfo.name])}
      />
      <section className="container">
        <Title id="listOfEmoji">{singlePageInfo.name}</Title>
        <br />
        <FlexDiv maxWidth="600px" margin="auto">
          <Input
            fontSize="4rem"
            fontSizeMobile="3rem"
            border="0"
            style={{ textAlign: "center" }}
            type="text"
            aria-label="emoji"
            role="img"
            value={singlePageInfo.emoji}
            readOnly
          />
          <p className="h4">{singlePageInfo.name}</p>
          <p className="h4">codepoints: {singlePageInfo.codepoints}</p>
          <br />
          <h3>Copy and Paste</h3>
          <p>Copy and paste {singlePageInfo.name} emoji</p>
          <Field>
            <Control>
              <Input
                maxWidth="100px"
                className="borderedLeft"
                aria-label="emoji-Small"
                role="img"
                value={singlePageInfo.emoji}
                ref={smallInput}
                readOnly
              />
            </Control>
            <Control>
              <Button
                margin="0"
                className="staticButton mb-2"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                onClick={copyButton}
              >
                Copy
              </Button>
            </Control>
          </Field>
          <br />

          <Table>
            <thead>
              <tr>
                <th>
                  <abbr title="Position"></abbr>
                </th>
                <th>Name</th>
                <th>Emoji</th>
                <th>Code Point</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>1</th>
                <td style={{ color: "blue" }}>{singlePageInfo.name}</td>
                <td>{singlePageInfo.emoji}</td>
                <td>U+{singlePageInfo.codepoints.split(" ").join(" U+")}</td>
              </tr>
            </tbody>
          </Table>
        </FlexDiv>
        {/* <p>&#x1F525</p>
          <span>{"\u{1F38C}"}</span> */}
        <br />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <UsageOfCodePoint
              emoji={singlePageInfo.emoji}
              codepoints={singlePageInfo.codepoints}
            />
          </React.Suspense>
        )}
        <br />
        {show && (
          <Popup
            title="Emoji have been copied"
            emoji={emoji}
            close={() => setShow(false)}
          />
        )}
        <br />
        <Dropdown className="mb-4">
          <Button aria-haspopup="true" aria-controls="dropdown-menu">
            Emojis Category
            <Icon>
              <ArrowDown />
            </Icon>
          </Button>
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <DropDownList list={allPages} />
            </React.Suspense>
          )}
        </Dropdown>
        <br />

        <ScrollEmojiWindow pageItems={everyEmoji} mycopy={mycopy} />
        <br />
        <SeeAlso seeAlsoList={seeAlaoArray} />
        <br />
      </section>
    </Layout>
  )
}

export default EmojiNamesList
