import React from "react"
import { Link } from "gatsby"
import { VariableSizeGrid as Grid } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"
import { BoxShadowWindow, Input } from "../../../StyleComponents/styles"

const ScrollEmojiWindow = ({ pageItems, mycopy }) => {
  return (
    <BoxShadowWindow>
      <div
        style={{
          padding: "15px",
          height: "100vh",
        }}
      >
        <AutoSizer>
          {({ width, height }) => {
            const columnCount = width <= 400 ? 2 : 5
            const rowHeight = 200
            const chunk = (arr, size) =>
              Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
                arr.slice(i * size, i * size + size)
              )
            const ids = chunk(
              Array.from({ length: pageItems.length }).map((_, i) => i),
              columnCount
            )
            const Row = ({ columnIndex, rowIndex, style }) => {
              let emote =
                pageItems[ids[rowIndex][columnIndex]] !== undefined
                  ? pageItems[ids[rowIndex][columnIndex]]
                  : ""

              return (
                <label style={style} className="p-2">
                  <Input
                    type="text"
                    fontSize="3rem"
                    fontSizeMobile="3rem"
                    marginBottomLastChild="0"
                    role="img"
                    value={emote !== "" ? emote.emoji : ""}
                    onClick={mycopy}
                    readOnly
                  />
                  {emote !== "" ? (
                      <Link
                        to={`/${emote.name
                          .replace(":", "")
                          .trim()
                          .split(" ")
                          .join("-")
                          .toLowerCase()}-emoji/`}
                      >
                        {emote.name}
                      </Link>
                  ) : (
                    ""
                  )}
                </label>
              )
            }
            return (
              <Grid
                height={height}
                width={width}
                rowCount={ids.length}
                rowHeight={() => rowHeight}
                columnCount={columnCount}
                columnWidth={() => 150}
              >
                {Row}
              </Grid>
            )
          }}
        </AutoSizer>
      </div>
    </BoxShadowWindow>
  )
}

export default React.memo(ScrollEmojiWindow)
